import { Form, Formik } from 'formik';
import { useMemo, useState } from 'react';
import { useIntercom } from 'react-intercom-hook';
import * as yup from 'yup';
import { AddressInput } from '../../gql/graphql';
import { useCreateOrUpdateUpsMerchantAccountMutation } from '../../operations/mutations/createOrUpdateUpsMerchant';
import { useUpdatePreferredUpsMerchantAccountAddressMutation } from '../../operations/mutations/updatePreferredUpsMerchantAccountAddress';
import { usePreferredMerchantAccountAddressQuery } from '../../operations/queries/preferredMerchantAccountAddress';
import getPostcodeAndZip4 from '../../utils/getPostcodeAndZip4';
import withIntercomLink from '../../utils/withIntercomLink';
import Alert from '../Alert';
import { ExternalLink, FakeLink } from '../Link';
import Checkbox from '../form/Checkbox';
import FormControl from '../form/FormControl';
import { Col, Row } from '../layout/Grid';
import PageSubtitle from '../layout/PageSubtitle';
import Loading from '../loading/PageLoading';
import PhysicalAddressSubform from '../subforms/PhysicalAddressSubform';
import { physicalAddressValidationSchema } from '../subforms/physicalAddressSubformUtls';
import Modal, { ModalButtonBar } from './Modal';

type UpsAccountModalProps = {
  open: boolean;
  buttonText: string;
  headline: string;
  onCancel: () => void;
  onAccountCreatedOrUpdated: () => void;
  showPickupText?: boolean;
};

function UpsAccountModal({
  open,
  buttonText,
  headline,
  onCancel,
  onAccountCreatedOrUpdated,
  showPickupText = true,
}: UpsAccountModalProps) {
  const [
    createOrUpdateUpsMerchantAccount,
    { loading: merchantAccountSubmitting, data: merchantData },
  ] = useCreateOrUpdateUpsMerchantAccountMutation();
  const { show: showIntercomArticle } = useIntercom();
  const { data: preferredMerchantAccountData, loading: addressLoading } =
    usePreferredMerchantAccountAddressQuery('ups');
  const [updatePreferredUpsMerchantAddress, { loading: addressUpdating }] =
    useUpdatePreferredUpsMerchantAccountAddressMutation();

  const submitMerchantAccount = async () => {
    const { data } = await createOrUpdateUpsMerchantAccount();
    if (!data) return;
    const {
      createOrUpdateUpsMerchantAccount: { accountStatus, errorMessage },
    } = data;
    if (!errorMessage && accountStatus === 'OK') {
      onAccountCreatedOrUpdated();
    }
  };

  const [isAddressRetry, setIsAddressRetry] = useState(false);

  const updateAddress = async (physicalAddress: AddressInput) => {
    const { postcode, zip4 } = getPostcodeAndZip4(physicalAddress.postcode);

    await updatePreferredUpsMerchantAddress({
      variables: {
        address: {
          fullName: physicalAddress.fullName,
          company: physicalAddress.company,
          address1: physicalAddress.address1,
          address2: physicalAddress.address2,
          postcode,
          zip4,
          regionCode: physicalAddress.regionCode,
          countryCode: physicalAddress.countryCode,
          city: physicalAddress.city,
        },
      },
    });
    submitMerchantAccount();
    setIsAddressRetry(true);
  };

  const merchantCreationError = merchantData?.createOrUpdateUpsMerchantAccount.errorMessage;
  const errorCategory = merchantData?.createOrUpdateUpsMerchantAccount.errorCategory ?? null;
  const hasAddressError =
    errorCategory === 'BILLING_ADDRESS' ||
    errorCategory === 'INVALID_STREET_OR_STATE_OR_POSTCODE' ||
    errorCategory === 'ADDRESS_IS_PO_BOX' ||
    errorCategory === 'MILITARY_ADDRESS';

  /**
   * We want to show the address modal on every error that we did not identify as being an outage of the UPS account service
   * even if we are not sure it is an address error, because most of the time changing the address helps
   * When there was a retry in the address modal, we want to keep showing the address modal
   */
  const showAddressForm =
    (merchantCreationError && errorCategory !== 'OUTAGE' && errorCategory !== 'MAINTENANCE') ||
    isAddressRetry;

  /**
   * On address errors, we only want to show the address modal and hide the error from the user to make it seem like everyting is ok
   * We show the error on retry to make it clear to the user that the changed address was not accepted and it is not just a broken
   * modal that does not do anything
   * If the error is not an address error, we want to show the error message, so it is clear that there could be something else
   * going wrong and it could be that changing the address does not help
   */
  const showAddressModalError = merchantCreationError && (isAddressRetry || !hasAddressError);

  const validationSchema = useMemo(() => physicalAddressValidationSchema('ups'), []);

  const renderPhysicalAddressForm = () =>
    !preferredMerchantAccountData ? (
      <Loading height="20vh" />
    ) : (
      <>
        {showAddressModalError && (
          <Alert variant="danger">
            {withIntercomLink(merchantCreationError, showIntercomArticle)}
          </Alert>
        )}
        <Formik
          enableReinitialize
          validationSchema={yup.object<any>({ physicalAddress: validationSchema })}
          initialValues={{
            physicalAddress: preferredMerchantAccountData.company.preferredMerchantAccountAddress,
          }}
          onSubmit={(values) => updateAddress(values.physicalAddress)}
          validateOnMount
        >
          <Form>
            <Row>
              <Col md={12}>
                UPS needs to confirm the physical address of your home, office, or warehouse.
              </Col>
              <Col md={12}>
                This will not change the Ship From Address that’s printed on the label.
              </Col>
              <Col md={12}>
                <PageSubtitle>Update your Physical Address and try again:</PageSubtitle>
              </Col>
              <Col md={12}>
                <PhysicalAddressSubform namespace="physicalAddress" carrier="ups" />
              </Col>
              <Col md={12}>
                If you need any help just{' '}
                <FakeLink
                  onClick={() => {
                    showIntercomArticle();
                    onCancel();
                  }}
                >
                  chat with our support
                </FakeLink>
                !
              </Col>
              <Col md={12}>
                <ModalButtonBar
                  cancelText="Cancel & Choose a Different Carrier"
                  cancelOnClick={onCancel}
                  buttonText="Try again"
                  buttonVariant="success"
                  buttonType="submit"
                  buttonSize="large"
                  buttonProgress={addressUpdating || merchantAccountSubmitting}
                  buttonDisabled={addressUpdating || addressLoading || merchantAccountSubmitting}
                />
              </Col>
            </Row>
          </Form>
        </Formik>
      </>
    );

  const renderAgreementContent = () => (
    <>
      {merchantCreationError && (
        <Alert variant="danger">
          {withIntercomLink(merchantCreationError, showIntercomArticle)}
        </Alert>
      )}
      <Row>
        {showPickupText && (
          <Col md={12} spaceBelow>
            You can schedule pickups on the <b>Ship</b> page or drop UPS packages off at over 85,000
            locations, including The UPS Store® and Drop Boxes, CVS Pharmacy, Michaels Stores,
            Advance Auto Parts, and local retailers that offer UPS services.
          </Col>
        )}
        <Col md={12} spaceBelow>
          To proceed, please accept the terms below.
        </Col>
        <Formik
          initialValues={{ agreed: false }}
          onSubmit={submitMerchantAccount}
          validationSchema={yup.object<{ agreed: boolean }>({
            agreed: yup.boolean().required().isTrue(),
          })}
        >
          <Form>
            <Col md={12}>
              <FormControl
                as={Checkbox}
                name="agreed"
                type="checkbox"
                label={
                  <>
                    By checking this box, I accept and agree to the
                    <ExternalLink href="https://www.ups.com/assets/resources/media/en_US/Ground-Saver.pdf">
                      {' '}
                      UPS Terms and Conditions{' '}
                    </ExternalLink>
                    and the
                    <ExternalLink href="https://www.ups.com/assets/resources/media/en_US/UTA.pdf">
                      {' '}
                      UPS Technology Agreement
                    </ExternalLink>
                    , and I will not ship any items in violation of the
                    <ExternalLink href="https://www.ups.com/us/en/support/shipping-support/shipping-special-care-regulated-items/prohibited-items.page">
                      {' '}
                      UPS List of Prohibited and Restricted Items for Shipping
                    </ExternalLink>
                    .
                  </>
                }
              />
            </Col>
            <Col md={12}>
              <ModalButtonBar
                buttonText={buttonText}
                buttonVariant="success"
                buttonSize="xLarge"
                buttonProgress={merchantAccountSubmitting}
                buttonDisabled={merchantAccountSubmitting}
                buttonType="submit"
              />
            </Col>
          </Form>
        </Formik>
      </Row>
    </>
  );

  return (
    <Modal
      width={800}
      open={open}
      onClose={onCancel}
      title={showAddressForm ? 'UPS needs more information to continue' : headline}
    >
      {showAddressForm ? renderPhysicalAddressForm() : renderAgreementContent()}
    </Modal>
  );
}
export default UpsAccountModal;
