import * as yup from 'yup';
import { AddressSubformValues } from './AddressSubform';

// note: for consistency with the way we export other validation schemata, a named export is in order here
// eslint-disable-next-line import/prefer-default-export
export const physicalAddressValidationSchema = (carrier: 'ups' | 'usps') =>
  yup.object<AddressSubformValues>({
    fullName:
      carrier === 'ups'
        ? yup
            .string()
            .required()
            .fullName()
            .matches(
              /^[\S]{1,25}( [\S]{1}[.]{0,1})? [\S]{1,50}$/,
              'First name must be shorter than 25 characters, middle name must be initials only',
            )
        : yup.string().required().fullName().lettersSpaces().max(40),
    company: yup.string().defined().default('').max(40),
    address1: yup.string().required().noPoBox().max(35),
    address2: yup.string().defined().default('').noPoBox().max(35),
    city: yup.string().required().max(40),
    regionCode: yup.string().required(),
    postcode: yup.string().required().usZip(),
    countryCode: yup.string().required(),
    phone:
      carrier === 'usps'
        ? yup
            .string()
            .required()
            .matches(/^[0-9]{10}$/, 'A 10 digit phone number is required')
        : yup.string().defined(),
  });
