import { useQuery } from '@apollo/client';
import { gql } from '../../gql';

export const accountSettingsQuery = gql(/* GraphQL */ `
  query AccountSettingsQuery {
    timezones {
      ianaName
      description
    }
    user {
      id
      firstName
      lastName
      email
      emailVerified
      twoFactorAuthenticationProviders {
        provider
        confirmed
      }
      timezone
    }
    notificationsSettings {
      carrierAdjustmentsNotificationEnabled
      paymentReceiptsNotificationEnabled
      paymentFailuresNotificationEnabled
      newLoginNotificationEnabled
      carrierAdjustmentDisputesEnabled
      newFeaturesAnnouncementsNotificationEnabled
    }
  }
`);

export const useAccountSettingsQuery = () => useQuery(accountSettingsQuery);
