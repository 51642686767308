import intersection from 'lodash.intersection';
import * as yup from 'yup';
import { MINIMUM_CC_PROVIDER_AMOUNT } from '../../constants';
import { BatchQuery } from '../../gql/graphql';
import formatCurrency from '../../utils/formatCurrency';
import roundFloat from '../../utils/roundFloat';
import { FinishPurchaseSubformValues } from './FinishPurchaseSubform';
import convertCurrencyToNumber from '../../utils/convertCurrencyToNumber';

export const calculateMinimumCharge = (
  accountBalance: number,
  totalCost: number,
  defaultChargeAmount?: number,
): number => {
  // If the user has enough credit to pay for the label, the total charge amount is 0.

  if (accountBalance >= totalCost) {
    return 0;
  }
  // This is the smallest amount the user has to be charged from their payment method in order to pay for the label.
  const minimumCharge = totalCost - accountBalance;

  // The minimum CC provider amount is the smallest amount the user can charge to their card.
  return Math.max(MINIMUM_CC_PROVIDER_AMOUNT, minimumCharge, defaultChargeAmount ?? 0);
};

export function calcEndingAccountBalance(
  accountBalance: number,
  totalCost: number,
  totalCharge: string,
) {
  return roundFloat(accountBalance - totalCost + convertCurrencyToNumber(totalCharge), 2);
}

export const finishPurchaseSubformInitialValues = ({
  totalCost,
  accountBalance,
  defaultPaymentSourceId,
  defaultChargeAmount,
}: {
  totalCost: number;
  accountBalance: number;
  defaultPaymentSourceId: string;
  defaultChargeAmount: number;
}): FinishPurchaseSubformValues => {
  const totalChargeAmount = calculateMinimumCharge(accountBalance, totalCost, defaultChargeAmount);

  return {
    totalCharge: formatCurrency(totalChargeAmount, { showDollarSign: false }),
    paymentSourceId: defaultPaymentSourceId,
    endingAccountBalance: undefined, // Just used for validation
  };
};

export const finishPurchaseValidationSchema = ({
  accountBalance,
  totalCost,
}: {
  accountBalance: number;
  totalCost: number;
}) =>
  yup.object<FinishPurchaseSubformValues>({
    totalCharge: yup.string().required(''),
    paymentSourceId: yup.string().defined(),
    endingAccountBalance: yup
      .mixed<void>()
      .test('charge-is-greater-than-cost', '', function chargeIsGreaterThanCost() {
        const totalChargeFloat = parseFloat(this.parent.totalCharge?.replace(/,/g, '')) || 0.0;
        const minimumTotalCharge = calculateMinimumCharge(accountBalance, totalCost);
        const formattedMinimumCharge = formatCurrency(minimumTotalCharge);

        // Prevent float comparison issues by performing an extra check on the 0.00 case
        if (
          totalChargeFloat < minimumTotalCharge &&
          minimumTotalCharge.toFixed(2) !== totalChargeFloat.toFixed(2)
        ) {
          return this.createError({
            message: `YARrrr, y'ee must fill y'er treasure chest with a minimum of ${formattedMinimumCharge}`,
            path: this.path,
          });
        }

        return true;
      }),
  });

export function getAvailableShipDates(rateGroups: BatchQuery['batch']['rateGroups']) {
  // Get the intersection of all available ship dates
  return intersection(
    ...rateGroups
      // Get all rate summaries
      .map((rateGroup) => rateGroup.rateSummaries)
      .flat()
      // Only include rate summaries that are available
      .filter((rateSummary) => rateSummary.shipmentCount > 0)
      // Get all available ship dates
      .map((rateSummary) => rateSummary.availableShipDates),
  ).sort();
}
