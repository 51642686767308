import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { SPACING } from '../../../styles/spacing';
import LandlubberContent from '../../layout/LandlubberContent';
import FormControl from '../../form/FormControl';
import OneTimePassword from '../../form/OneTimePassword';
import Label from '../../form/Label';
import { Col, Row } from '../../layout/Grid';
import PageTitle from '../../layout/PageTitle';
import ProgressButton from '../../form/ProgressButton';

export default function TwoFactorAuthenticationChallengePage() {
  const form = useRef<HTMLFormElement | null>(null);
  const [loading, setLoading] = useState(false);

  const Styled = {
    PageTitle: styled(PageTitle)`
      margin-bottom: ${SPACING.xl};
    `,
  };

  return (
    <LandlubberContent>
      <Formik
        initialValues={{ code: '' }}
        validationSchema={() =>
          yup.object({
            code: yup
              .string()
              .required()
              .min(6)
              .max(6)
              .matches(/^[0-9]{6}$/, 'Only numbers are allowed'),
          })
        }
        onSubmit={({ code }) => {
          setLoading(true);
          const input = document.createElement('input');
          input.setAttribute('name', 'code');
          input.setAttribute('type', 'hidden');
          input.setAttribute('value', code);
          form.current?.appendChild(input);
          form.current?.submit();
        }}
      >
        <Form method="POST" action="/user/two-factor-authentication/confirm" ref={form}>
          <Row spaceBelow>
            <Col>
              <Styled.PageTitle>Two Factor Challenge</Styled.PageTitle>
              <p>
                Please confirm access to your account by entering the verification code provided to
                you.
              </p>
            </Col>
          </Row>
          <Row spaceBelow justify="center">
            <Col>
              <Label htmlFor="one-time-password-field">Verification Code</Label>
              <FormControl id="one-time-password-field" name="code" as={OneTimePassword} />
            </Col>
          </Row>
          <Row>
            <Col>
              <ProgressButton
                type="submit"
                fullWidth
                noWrap
                size="xLarge"
                progress={loading}
                disabled={loading}
              >
                Yarrrr, log me in
              </ProgressButton>
            </Col>
          </Row>
        </Form>
      </Formik>
    </LandlubberContent>
  );
}
